<template>
  <div class="stage">
    <Search :searchOptions="options" @search="getSearch"> </Search>
    <div class="stage_content">
      <Table
        :tableOptions="tableOptions"
        :tableData="tableData"
        :page="page"
        :total="total"
        @update:page="updatePage"
      >
        <template v-slot:action="{ scope }">
          <div class="btns">
            <el-button type="text" @click="look('look', scope)"
              >查看明细</el-button
            >
          </div>
        </template>
      </Table>
    </div>
    <!-- 弹窗表单 -->
    <el-dialog
      title="积分明细"
      :visible.sync="dialogVisibles"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
    >
      <Table
        :tableOptions="detailTableOptions"
        :tableData="detailTable"
        :page="pages"
        :total="detailTotal"
        @update:page="updatePages"
      ></Table>
    </el-dialog>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import Table from "../../components/Table.vue";
export default {
  components: {
    Search,
    Table,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,

      mode: "add",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      pages: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      options: [
        {
          type: "input",
          placeholder: "人员姓名",
          prop: "name",
        },
      ],
      tableData: [],
      tableOptions: [
        {
          label: "人员姓名",
          prop: "userName",
        },
        {
          label: "当前积分",
          prop: "currentIntegral",
        },
        {
          label: "累计积分",
          prop: "totalIntegral",
        },
      ],
      detailTableOptions: [
        { label: "操作", prop: "changeRemark" },
        {
          label: "积分明细",
          prop: "changeIntegral",
        },
        {
          label: "时间",
          prop: "createTime",
        },
      ],
      detailTable: [],
      detailTotal: 0,
      userId: null,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    getSearch(val) {
      this.page = {
        pageNum: 1,
        pageSize: 10,
        ...val,
      };
      this.loadData();
    },
    // 加载数据
    async loadData() {
      const { flag, data } = await this.$get(
        "/xng/backed/task/selectAllUserIntegralPageListByName",
        this.page
      );
      if (flag) {
        this.total = data.total;
        this.tableData = data.rows;
      }
    },
    async look(type, row) {
      const { flag, data } = await this.$get(
        `/xng/backed/task/selectUserIntegralRecordPageListByUserId`,
        { userId: row.id, ...this.pages }
      );
      if (flag) {
        this.userId = row.id;
        this.detailTable = data.rows;
        this.detailTotal = data.total;
        this.dialogVisibles = true;
      }
    },
    modalShow() {
      this.dialogVisible = true;
    },
    updatePage(val) {
      this.page.pageNum = val;
      this.loadData();
    },
    async updatePages(val) {
      this.pages.pageNum = val;
      const { flag, data } = await this.$get(
        `/xng/backed/task/selectUserIntegralRecordPageListByUserId`,
        { userId: this.userId, ...this.pages }
      );
      if (flag) {
        this.detailTable = data.rows;
        this.detailTotal = data.total;
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
        })
        .catch((_) => {});
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisibles = false;
          this.pages = {
            pageNum: 1,
            pageSize: 10,
          };
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.stage {
  width: 100%;
  .btns {
    padding: 0 10px;
  }
  .stage_content {
    width: 100%;
  }
}
</style>
